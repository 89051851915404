<template >
  <div class="container">
    <p
      ref="paragraphe"
      v-dompurify-html="renderMarkdown(content).rendered"
      class="justify"
      :class="{ 'cutoff-text': !isOpen }"
      :style="{ '--max-lines': maxLines }"
    ></p>
    <button v-if="showReadMoreButton" class="button" @click="toggleRead">
      {{ isOpen ? 'Voir moins' : 'Voir le détail' }}
    </button>
  </div>
</template>
  
<script lang="ts" setup>
import { renderMarkdown } from '@/lib/markdown';
const { content } = defineProps<{
  content: string;
  maxLines: number;
}>();
const isOpen = ref(false);
const showReadMoreButton = ref(false);
const paragraphe = ref<HTMLElement | null>(null);

const checkAndToggleReadMore = () => {
  nextTick().then(() => {
    if (paragraphe.value) {
      showReadMoreButton.value = paragraphe.value.scrollHeight > paragraphe.value.clientHeight;
    }
  });
};

onMounted(checkAndToggleReadMore);

watch(
  () => content,
  () => {
    checkAndToggleReadMore();
  }
);

const toggleRead = () => {
  isOpen.value = !isOpen.value;
};
</script>
  
  <style lang="scss" scoped>
@use '$/colors.scss';

.container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  word-break: break-word;

  .justify {
    text-align: justify;
  }
}

.cutoff-text {
  --max-lines: var(--max-lines, 3);

  overflow: hidden;
  display: -webkit-box;

  -webkit-box-orient: vertical;
  -webkit-line-clamp: var(--max-lines);
}

.button {
  color: colors.$primary-red;
  text-decoration: underline;
}
</style>
  